import type { LoggerInterface } from './types'


let logger: LoggerInterface

if (__TEST__) {
  logger = require('./testLogger').default
}
else if (__SERVER__) {
  logger = require('./serverLogger').default
}
else {
  logger = require('./clientLogger').default
}


export default logger
